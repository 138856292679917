<template lang="html">
  <div id="vue-gf-information-digest">
    <div
      class="container-fluid px-0"
      :if="componentToLoad"
    >
      <component
        :is="componentToLoad"
        :configuration-key="configurationKey"
        :after-process-call-back="afterProcessCallBack"
        :event-campaign-id="eventCampaignId"
        :beneficiary-id="beneficiaryId"
        :fundraising-page-id="fundraisingPageId"
        :user-id="userId"
        :component-settings="mode.settings"
        :region-code="regionCode"
        :roles="roles"
        :api-domain="apiDomain"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Store from "./common/store";
import gfComponents from "./components";
import infiniteScroll from "vue-infinite-scroll";
import gmapsInit from "./common/gmapsInit";
import VModal from 'vue-js-modal'

Vue.use(infiniteScroll);
Vue.use(VModal);
export default {
    name: "ComponentLoader",

    props: [
        "apiDomain",
        "configurationKey",
        "afterProcessCallBack",
        "eventCampaignId",
        "beneficiaryId",
        "regionCode",
        "fundraisingPageId",
        "userId",
        "mode",
        "roles" // This should be an array of roles as string
    ],

    data() {
        return {
            store: Store,
            componentMode: '',
            gfComponents: gfComponents
        };
    },

    computed: {
        componentToLoad() {
            if (!this.componentMode || this.componentMode == '')
                return null;

            return gfComponents.LoadComponent(this.componentMode);
        }
    },

    created() {
        if (this.eventCampaignId && this.eventCampaignId.length > 0)
            this.store.setEventId(this.eventCampaignId);

        if (this.fundraisingPageId && this.fundraisingPageId.length > 0)
            this.store.setFundraisingPageId(this.fundraisingPageId);

        if (this.apiDomain && this.apiDomain.length > 0)
            this.store.setApiDomain(this.apiDomain);
    },

    async mounted() {
        if (this.regionCode && this.regionCode.length > 0)
            this.store.setRegionCode(this.regionCode);

        if (this.mode) {
            this.componentMode = this.mode.name;
            this.store.setComponentMode(this.mode.name, this.mode.settings);
        } else {
            this.componentMode = "supportersFeed";
            this.store.setComponentMode("supportersFeed", {});
        }

        if (this.apiDomain) this.store.setApiDomain(this.apiDomain);
        if (this.roles) this.store.setRoles(this.roles);

        if (this.store.state.componentMode == "fundraisingPageFeed")
            await this.initialiseGoogleMaps();
    },

    methods: {
        async initialiseGoogleMaps() {
            const gmap = await gmapsInit(
                this.store.state.componentSettings.googleMapsApiKey
            );

            this.$nextTick(() => this.store.setMapsAsInitialised(gmap));
        }
    }
};
</script>

<style lang="scss" scoped>
#vue-gf-information-digest /deep/ {
    @import "./assets/custom.scss";
    @import "../node_modules/bootstrap/scss/_functions.scss";
    @import "../node_modules/bootstrap/scss/_variables.scss";
    @import "../node_modules/bootstrap/scss/vendor/_rfs.scss";
    @import "../node_modules/bootstrap/scss/mixins/_lists.scss";
    @import "../node_modules/bootstrap/scss/mixins/_border-radius.scss";
    @import "../node_modules/bootstrap/scss/mixins/_hover.scss";
    @import "../node_modules/bootstrap/scss/mixins/_deprecate.scss";
    @import "../node_modules/bootstrap/scss/mixins/_text-hide.scss";
    @import "../node_modules/bootstrap/scss/mixins/_text-emphasis.scss";
    @import "../node_modules/bootstrap/scss/mixins/_text-truncate.scss";
    @import "../node_modules/bootstrap/scss/mixins/_breakpoints.scss";
    @import "../node_modules/bootstrap/scss/mixins/_table-row.scss";
    @import "../node_modules/bootstrap/scss/_type.scss";
    @import "../node_modules/bootstrap/scss/utilities/_align.scss";
    @import "../node_modules/bootstrap/scss/utilities/_text.scss";
    @import "../node_modules/bootstrap/scss/utilities/_sizing.scss";
    @import "../node_modules/bootstrap/scss/bootstrap-grid.scss";
    @import "../node_modules/bootstrap/scss/_tables.scss";
    @import "../node_modules/bootstrap/scss/_nav.scss";
    @import "../node_modules/bootstrap/scss/_card.scss";
}
#vue-gf-information-digest {
    font-family: "bariol_regularregular";
}
</style>
