<template lang="html">
  <div>
    <div v-if="headless">
      <div class="heading-3 my-1">
        {{ supportersTitle }}
      </div>
    </div>
    <table
      class="table supporters-feed-table"
      :class="{ 'table-striped': !headless }"
    >
      <thead
        v-if="!headless"
        id="sf-tbl-head"
        class="thead-dark"
      >
        <tr>
          <th
            v-if="showSupporters"
            class="px-0"
            scope="col"
          >
            <div>
              {{ supportersTitle }}
            </div>
          </th>
          <th
            v-if="showAmount"
            class="px-0"
            scope="col"
          >
            {{ amountTitle }}
          </th>
          <th
            v-if="showMessage"
            class="px-0"
            scope="col"
          >
            {{ messageTitle }}
          </th>
        </tr>
      </thead>
      <tbody
        v-infinite-scroll="loadFromScroll"
        infinite-scroll-disabled="loading"
        infinite-scroll-distance="10"
        infinite-scroll-throttle-delay="200"
        infinite-scroll-immediate-check="false"
      >
        <tr
          v-for="(payment, i) in feed"
          :key="i"
        >
          <td
            v-if="headless && showSupporters"
            class="px-0"
          >
            <div class="row col text-muted sf-lastupdated">
              {{ formattedDateTime(payment.LastUpdated) }}
            </div>
            <div class="row col font-weight-bold">
              {{ payment.Name }}
            </div>
          </td>
          <td
            v-if="!headless && showSupporters"
            class="px-0"
          >
            {{ payment.Name }}
          </td>
          <td
            v-if="showAmount"
            class="px-0 align-middle"
          >
            <div
              :class="{
                'font-weight-bold': headless
              }"
            >
              {{ $_CurrencyHelper_formattedFinancialFigure(payment.HierarchyTotal, decimalPlaces, currencySymbol) }}
            </div>
          </td>
          <td
            v-if="showMessage"
            class="px-0"
          >
            {{ payment.Message }}
          </td>
        </tr>
      </tbody>
    </table>

    <loader
      :color="spinnerColour"
      :loading="loading"
      size="36px"
    />

    <template v-if="showMoreButton && ! loading && morePages">
      <div class="text-center">
        <button
          id="show-more-button"
          class="btn btn-light border"
          @click="fetchFeedAndLoad"
        >
          {{ showMoreButtonText }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import constants from "../common/services/informationDigest/constants"
import logger from "../common/logger";
import Loader from "vue-spinner/src/ClipLoader.vue";
import moment from "moment";
import GfInformationDigestBase from "../GfInformationDigestBase.vue"
import store from "../common/store";
import currencyHelper from './Common/CurrencyHelper.vue';
import InformationDigestService from "@gf/gf-api-services/services/informationdigest/InformationDigestService"
import * as GfLocalisation from '@gf/gf-localisation'
import { currencyUtility } from "@gf/gf-utility"

export default {
    name: "SupportersFeed",
    components: { Loader },
    mixins:[GfInformationDigestBase, currencyHelper],
    props:{
       apiDomain:{
            type: String,
            default: '',
        }
    },
    data() {
        return {
            supportersTitle: "Recent Donations",
            amountTitle: "Amount",
            currencySymbol: constants.DEFAULT_CURRENCY_SYMBOL,
            decimalPlaces: 2,
            messageTitle: "Message",
            headless: false,
            showSupporters: true,
            showAmount: true,
            showMessage: true,
            showMoreButton: false,
            showMoreButtonText: 'Show More',
            feed: [],
            spinnerColour: "#000",
            loading: true,
            size: 50,
            resultsToPerBatch: 10,
            currentPage: 1,
            morePages: true,
            state: store.state,
            sortBy: "",
            sortOrder: "",
            includeSubEvents: false,
            feedMode: "event",
            logger,
            GfLocalisation,
            currencyUtility: currencyUtility.default
        };
    },

    async mounted() {
        if (this.componentSettings) {
            if (this.componentSettings.supportersTitle)
                this.supportersTitle = this.componentSettings.supportersTitle;
            if (this.componentSettings.amountTitle)
                this.amountTitle = this.componentSettings.amountTitle;
            if (this.componentSettings.messageTitle)
                this.messageTitle = this.componentSettings.messageTitle;
            if (this.componentSettings.showSupporters != null)
                this.showSupporters = this.componentSettings.showSupporters;
            if (this.componentSettings.showAmount != null)
                this.showAmount = this.componentSettings.showAmount;
            if (this.componentSettings.showMoreButton != null)
                this.showMoreButton = this.componentSettings.showMoreButton;
            if (this.componentSettings.showMessage != null)
                this.showMessage = this.componentSettings.showMessage;
            if (this.componentSettings.headless != null) {
                this.headless = this.componentSettings.headless;
                this.amountTitle = "";
            }
            if (this.componentSettings.size != null)
                this.size = this.componentSettings.size;
                this.resultsToPerBatch = this.componentSettings.size;
            if (this.componentSettings.includeSubEvents != null)
                this.includeSubEvents = this.componentSettings.includeSubEvents;
            if (this.componentSettings.sortOrder != null)
                this.sortOrder = this.componentSettings.sortOrder;
            if (this.componentSettings.sortBy != null)
                this.sortBy = this.componentSettings.sortBy;
            if (this.componentSettings.decimalPlaces != null)
                this.decimalPlaces = this.componentSettings.decimalPlaces;
            if (this.componentSettings.itemsPerPage != null) {
                this.resultsToPerBatch = this.componentSettings.itemsPerPage;
            }

            this.showMoreButtonText = this.componentSettings.showMoreButtonText != null 
                    ? this.componentSettings.showMoreButtonText
                    : (
                        this.GfLocalisation.getLocale('show_more') == 'show_more' 
                        ? 'Show More'
                        : this.GfLocalisation.getLocale('show_more')
                      );
      }

      this.feedMode = this.componentSettings.feedMode || 'event'
      var resCurrency = await this.currencyUtility.getCurrencyInfo(this.apiDomain, this.eventCampaignId)
            if (typeof resCurrency.symbol != 'undefined') {
              this.currencySymbol = resCurrency.symbol
            }

      await this.$nextTick()
      if (this.headless) {
        var el = document.querySelector(".heading-3");
        if (el)
          this.spinnerColour = window.getComputedStyle(el).color;
      } else {
        var el = document.querySelector("#sf-tbl-head th");
        if (el) {
          this.spinnerColour = window.getComputedStyle(
                  el
          ).backgroundColor;
        }      
      }
      await this.fetchFeedAndLoad();
    },

    methods: {
        async loadFromScroll() {
            if (!this.showMoreButton)
                await this.fetchFeedAndLoad()
        },

        async fetchFeedAndLoad() {
            if (!this.morePages) return;

			      this.loading = true;
            var eventFeedResults =await this.getDigestByParentId()
            logger.logInfo('eventFeedResults', eventFeedResults);

            eventFeedResults.PaymentDigests.forEach((o) => {
              this.feed.push(o);
            });

            this.feed.concat(
              eventFeedResults.PaymentDigests
            );

            this.loading = false;
            this.morePages = this.isMoreDataAvailable(eventFeedResults);

            this.currentPage += 1;
        },

        async getDigestByParentId() {
            var informationDigestService = new InformationDigestService(this.apiDomain);

            switch(this.feedMode) {
              case 'event':
                return await informationDigestService.getEventPaymentDigest(this.eventCampaignId, this.resultsToPerBatch, this.currentPage, this.includeSubEvents, this.sortOrder, this.sortBy);
              case 'page':
                return await informationDigestService.getPagePaymentDigest(this.fundraisingPageId, this.resultsToPerBatch, this.currentPage, this.includeSubEvents, this.sortOrder, this.sortBy);
              default:
                return await informationDigestService.getEventPaymentDigest(this.eventCampaignId, this.resultsToPerBatch, this.currentPage, this.includeSubEvents, this.sortOrder, this.sortBy);
            }
        },

        isMoreDataAvailable(response) {
          return (
            response.ResultsReturned == this.resultsToPerBatch &&
            response.PageNumber <= response.TotalPages
          );
        },

        formattedDateTime(dateTime) {
            return moment(dateTime).format("LLL");
        }
    }
};
</script>
<style scoped>
.sf-lastupdated {
    font-size: 0.75em;
}

.sf-headless-table th {
    border-top: none !important;
    background-color: transparent !important;
}

.supporters-feed-table td {
    font-size: 15px;
}

.supporters-feed-table td:nth-child(1), .supporters-feed-table td:nth-child(2) {
    width: 30%;
    padding-left: 10px;
}
</style>
