<template lang="html">
  <div id="vue-gf-information-digest">
    <div class="container-fluid px-0">
      <modal
        v-if="showAsModal"
        :name="modalName"
        :adaptive="localModalSettings.adaptive"
        :height="localModalSettings.height"
        :width="localModalSettings.width"
        :max-width="localModalSettings.maxWidth"
        :delay="localModalSettings.delay"
        :scrollable="localModalSettings.scrollable"
      >
        <div class="pt-1 col-12 text-right">
          <font-awesome-icon
            class="close-icon"
            :icon="closeIcon"
            @click="closeModal"
          />
        </div>
        <ComponentLoader
          v-if="ready"
          class="p-2"
          :api-domain="apiDomain"
          :component-mode="componentMode"
          :configuration-key="configurationKey"
          :after-process-call-back="afterProcessCallBack"
          :event-campaign-id="eventId"
          :beneficiary-id="beneficiaryId"
          :fundraising-page-id="fundraisingPageId"
          :user-id="userId"
          :component-settings="mode.settings"
          :region-code="regionCode"
          :mode="mode"
          :roles="roles"
        />
      </modal>
      <ComponentLoader
        v-if="!showAsModal && ready"
        :api-domain="apiDomain"
        :component-mode="componentMode"
        :configuration-key="configurationKey"
        :after-process-call-back="afterProcessCallBack"
        :event-campaign-id="eventId"
        :beneficiary-id="beneficiaryId"
        :fundraising-page-id="fundraisingPageId"
        :user-id="userId"
        :component-settings="mode.settings"
        :region-code="regionCode"
        :mode="mode"
        :roles="roles"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Store from "./common/store";
import logger from "./common/logger";
import VModal from "vue-js-modal";
import ComponentLoader from "./ComponentLoader";
import Toasted from "vue-toasted";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import * as GfLocalisation from '@gf/gf-localisation'

Vue.use(VModal);
Vue.use(Toasted);

export default {
    name: "GfInformationDigest",

    components: {
        ComponentLoader,
        FontAwesomeIcon
    },

    props: [
        "apiDomain",
        "configurationKey",
        "afterProcessCallBack",
        "eventId",
        "beneficiaryId",
        "regionCode",
        "fundraisingPageId",
        "userId",
        "mode",
        "roles",
        "demo",
        "loadInModal",
        "modalDisplayIdSelector",
        "modalDisplayClassSelector",
        "modalSettings"
    ],

    data() {
        return {
            store: Store,
            closeIcon: faTimes,
            componentMode: "",
            showAsModal: false,
            localModalSettings: {
                height: "auto",
                width: "100%",
                scrollable: true,
                adaptive: true,
                delay: 100,
                maxWidth: "400"
            },
            modalName: "#component-modal",
            ready: false,
        };
    },

    created() {
        GfLocalisation.initLocale('informationDigest')

        if (localStorage && localStorage["GfDebug"] === "true")
            this.store.setToDebugMode();

        if (this.demo && this.demo === "true") {
            this.store.enableDemoMode();
        }else{
            this.store.disableDemoMode();
        }

        if (typeof this.modalSettings !== 'undefined') {
          if (typeof this.modalSettings.height !== 'undefined') {
            this.localModalSettings.height = this.modalSettings.height
          }
          if (typeof this.modalSettings.width !== 'undefined') {
            this.localModalSettings.width = this.modalSettings.width
          }
          if (typeof this.modalSettings.scrollable !== 'undefined') {
            this.localModalSettings.scrollable = this.modalSettings.scrollable
          }
          if (typeof this.modalSettings.adaptive !== 'undefined') {
            this.localModalSettings.adaptive = this.modalSettings.adaptive
          }
          if (typeof this.modalSettings.delay !== 'undefined') {
            this.localModalSettings.delay = this.modalSettings.delay
          }
          if (typeof this.modalSettings.maxWidth !== 'undefined') {
            this.localModalSettings.maxWidth = this.modalSettings.maxWidth
          }
        }
    },

    mounted() {
        if (this.loadInModal == undefined) this.showAsModal = false;
        else {
            this.showAsModal = true;
            if (this.modalDisplayIdSelector) {
                this.modalName = this.modalDisplayIdSelector;
                this.bindModalTargetButton(`#${this.modalName}`);
            }

            if (this.modalDisplayClassSelector) {
                this.modalName = this.modalDisplayClassSelector;
                this.bindModalTargetButton(`.${this.modalName}`);
            }

            logger.logInfo(`configurationKey: ${this.configurationKey}`)
            logger.logInfo(
                "Showing In modal mode. Make sure you have set the modalDisplayIdSelector property"
            );
        }

        this.$root.$on('closeComponent', this.closeModal);
        this.$nextTick(() => this.ready = true);
    },
    methods: {
        bindModalTargetButton(selector) {
          document
            .querySelectorAll(selector)
            .forEach((el) => {
                el.addEventListener("click", e => {
                  e.preventDefault();
                  this.$modal.show(this.modalName);
                });
            });
        },

        closeModal() {
            this.$modal.hide(this.modalName);
        },
    }
};
</script>

<style lang="scss" scoped>
#vue-gf-information-digest /deep/ {
    @import "./assets/custom.scss";
    @import "../node_modules/bootstrap/scss/_functions.scss";
    @import "../node_modules/bootstrap/scss/_variables.scss";
    @import "../node_modules/bootstrap/scss/vendor/_rfs.scss";
    @import "../node_modules/bootstrap/scss/mixins/_lists.scss";
    @import "../node_modules/bootstrap/scss/mixins/_border-radius.scss";
    @import "../node_modules/bootstrap/scss/mixins/_hover.scss";
    @import "../node_modules/bootstrap/scss/mixins/_deprecate.scss";
    @import "../node_modules/bootstrap/scss/mixins/_text-hide.scss";
    @import "../node_modules/bootstrap/scss/mixins/_text-emphasis.scss";
    @import "../node_modules/bootstrap/scss/mixins/_text-truncate.scss";
    @import "../node_modules/bootstrap/scss/mixins/_breakpoints.scss";
    @import "../node_modules/bootstrap/scss/mixins/_table-row.scss";
    @import "../node_modules/bootstrap/scss/_type.scss";
    @import "../node_modules/bootstrap/scss/utilities/_align.scss";
    @import "../node_modules/bootstrap/scss/utilities/_text.scss";
    @import "../node_modules/bootstrap/scss/utilities/_sizing.scss";
    @import "../node_modules/bootstrap/scss/bootstrap-grid.scss";
    @import "../node_modules/bootstrap/scss/_tables.scss";
    @import "../node_modules/bootstrap/scss/_nav.scss";
    @import "../node_modules/bootstrap/scss/_card.scss";
}
#vue-gf-information-digest {
    font-family: "bariol_regularregular";
}

.close-icon {
    cursor: pointer;
}
</style>
